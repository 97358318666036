@use 'variables' as theme;
@use './everything' as *;

@include react-md-utils;

.rmd-button {
  font-family: theme.$font-regular;

  &.full {
    width: 100%;
  }

  &.primary {
    font-size: 1rem;

    &[disabled] {
      background-color: theme.$primary-color;
      color: theme.$white;
      opacity: .5;
    }
  }

  &.secondary {
    color: theme.$white;
    font-size: 1rem;

    &[disabled] {
      background-color: theme.$secondary-color;
      color: theme.$white;
      opacity: .5;
    }
  }
}
