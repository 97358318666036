@use '../../scss/variables' as *;

.sidebar-table-th {
  .rmd-button-unstyled {
    display: flex;
    flex-direction: row-reverse;
    font-family: $font-demi;
    justify-content: flex-end;
  }
}

.sidebar-table-th,
.sidebar-table-td {
  width: 28%;

  &:nth-child(1) {
    width: 16%;
  }
}
