@use '../../scss/variables' as *;

.container {
  height: calc(100vh - 64px);
  width: 100%;
}

.popupTitle {
  font-family: $font-demi;
  font-size: 16px;
  margin: 0 auto 4px;
}
