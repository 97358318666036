.location-tree {
  max-height: 19vh;
  overflow-y: auto;

  .branch {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;

    &.branch-lot {
      margin-left: 1.5rem;
    }

    &.branch-spot {
      margin-left: 3rem;
    }
  }
}
