* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

html,
body {
  color: $text-color;
  font-family: $font-regular;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  height: auto;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

button,
input {
  appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background: #eaeaea;
    color: #a8a8a8;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding: 0 1rem;
  position: relative;
}

.wrapper-full {
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.odometer {
  font-size: 1.75rem;

  &.odometer-auto-theme {
    font-family: $font-demi;
  }
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 500ms;
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 500ms;
}
