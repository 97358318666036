@use '../../scss/variables' as *;

.sidebar-container {
  background-color: #fff;
  border-radius: .625rem;
  box-shadow: 0 .375rem 2rem rgba(130, 141, 168, .2);
  color: $text-color;
  font-family: $font-regular;
  left: 0;
  margin: .75rem;
  max-height: calc(100vh - 90px);
  overflow-y: hidden;
  padding: .75rem;
  position: absolute;
  top: 0;
  width: 36.5rem;
  z-index: 100;
}

.sidebar-panel {
  box-shadow: none !important;
  margin: 0;
}

.sidebar-header.rmd-expansion-panel__header {
  font-family: $font-demi;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0 0 .75rem;
  padding: 0;
}

.sidebar-hero {
  border: $border;
  border-radius: .25rem;
  padding: .375rem;
}

.sidebar-divider {
  border-bottom: $border;
  margin: .625rem 0;
}

.sidebar-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.sidebar-metric {
  padding-left: .875rem;

  &:nth-child(1) {
    padding-left: .625rem;
  }

  &:nth-child(2),
  &:nth-child(3) {
    border-left: $border;
  }
}

.sidebar-metric-value {
  font-family: $font-demi;
  font-size: 1.75rem;
  line-height: 2.125rem;
}

.sidebar-metric-label {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.sidebar-metric-label-text {
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.sidebar-metric-label-legend {
  display: inline-block;
  height: 1rem;
  margin-right: .375rem;
  width: 1rem;
}

.sidebar-table-container {
  height: 30vh;
}
